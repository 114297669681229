import React from 'react'
import '../styles/productDetails.css'
import Helmet from 'react-helmet'

import img2 from '../images/Rect8.png'
import logo from '../images/headerLogo.png'
import Layout from '../components/layout'
import square from '../images/square.png'

import rectItems1 from '../images/dispenser/Rectangle 34.png'
import rectItems2 from '../images/dispenser/image 48.png'
import rectItems3 from '../images/dispenser/Rectangle 31.png'

import product1 from '../images/dispenser/image 50.png'
import product2 from '../images/dispenser/image 51.png'
import product3 from '../images/dispenser/image 52.png'
import product4 from '../images/dispenser/image 54.png'
import product5 from '../images/dispenser/image 69.png'
import product6 from '../images/dispenser/image 71.png'
import product7 from '../images/dispenser/image 72.png'
import product8 from '../images/dispenser/image 73.png'
import Contact from '../components/contact'




const Product6 = () => {

  return (

    
    <div className=' font-hind'>
      <Layout>
      <Helmet title="Dispenser & water coolers | Better Freezing Solutions"
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                        name:'description',
                        content:'Complete solution for refrigeration systems.We provide supermarket refrigeration,cold storage refrigeration,kitchen refrigeration medical refrigeration,freezers,coolers.',
                    }
                ]}
        />


        <div className='productDetailSection'>
          
          <div className='flex  relative'>
            <img className='img2 absolute right-0' src={img2} alt='' />

            <div className='PDItemsSection absolute right-0 z-30 flex sm:justify-end justify-center'>
              <div className='flex items-end'>
                <img className='PDImage1 absolute z-10' src={rectItems1} alt='item' />
                <div className='flex justify-center items-center'>
                  <img className='PDImage p-10 absolute' src={rectItems2} alt='item' />
                  <img className='PDImage2 ' src={rectItems3} alt='item' />
                </div>
              </div>
            </div>
          </div>

          
          
          <div className='productTitleSection flex justify-center text-white'>
            <div className='z-30'>
              
              <div className='flex justify-center items-center  sm:items-end sm:justify-start px-6'>
                <img className='logo ' src={logo} alt='' />
                <h1 className='productTitle hidden sm:flex text-white'>Water Dispenser & Water Cooler</h1>
                <h1 className='productTitle block sm:hidden text-white'>Water Dispenser &<br/>Water Cooler</h1>
              </div>
              
              <div className=''>
                <div className='PDListGrid grid lg:grid-cols-2  sm:grid-cols-2 grid-cols-1 xl:gap-8 xl:gap-y-2 lg:gap-5 lg:gap-y-2  md:gap-0 md:gap-y-1'>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image1' /><h1 className='PDListText'>Dispensor With Cooling Cabinet</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image2' /><h1 className='PDListText'>Table Top Dispensor</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image3' /><h1 className='PDListText'>Dispensor With Storage Cabinet</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image4' /><h1 className='PDListText'>Water Cooler With UV Purification</h1> </div>
                </div>
              </div>

              
            </div>
          </div>

        </div>

        <div className='flex justify-center'>
          <h1 className='PDText'>
            Now offering new cutting-edge range of models in supermarket refrigeration. These new  models are 
            sustainable, practical, elegant and consumes less power due to their smart design.
            <br/>
            <br/>
            Now offering new cutting-edge range of models in supermarket refrigeration. These new  models are 
            sustainable, practical, elegant and consumes less power due to their smart design.The range of Open Deck 
            Chillers & Freezers is designed aesthetically as well as with the use of path-breaking technology for 
            strong operations, preserving the freshness of foods, while maximizing display area for products stored. 
              
          </h1>
        </div>

        <div className='flex justify-center'>
          <div className='PDProductGrid grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 2xl:gap-10 gap-3 gap-y-5 pb-4'>
            <div className='PdProductItems flex justify-center items-center'><img className='p-16' src={product1} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-16' src={product2} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-16' src={product3} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-16' src={product4} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-16' src={product5} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-16' src={product6} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-16' src={product7} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-16' src={product8} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
          </div>
        </div>

        
        <Contact/>
      </Layout>
    </div>
  )
}

export default Product6
